$sizes: (
  'lg': 0.688rem $petz-spacing-md,
  'md': 0.438rem $petz-spacing-xs,
  'sm': $petz-spacing-4xs $petz-spacing-2xs,
  'xs': $petz-spacing-4xs $petz-spacing-3xs,
);

$solidColors: (
  'primary-brand': $petz-color-primary-brand,
  'primary-signature': $petz-color-primary-signature,
  'secondary-brand': $petz-color-secondary-brand,
  'sup-yellow': $petz-color-sup-yellow-mid,
  'sup-green': $petz-color-sup-green-darker-accent,
  'sup-red': $petz-color-sup-red-darker-accent,
  'sup-orange': $petz-color-chart-orange-mid,
  'sup-green-light': $petz-color-sup-green-light,
  'sup-green-dark': $petz-color-sup-green-darker-accent,
  'neutral-dark': $petz-color-neutral-darker-accent,
  'neutral-light': $petz-color-neutral-lighter-accent,
  'chart-blue': $petz-color-chart-blue-light,
  'chart-purple': $petz-color-chart-purple-light,
  'chart-pink': $petz-color-chart-pink-light,
  'chart-orange': $petz-color-chart-orange-light,
  'chart-orange-dark': $petz-color-chart-orange-dark,
);

$solidTextColors: (
  'primary-brand': $petz-color-neutral-white,
  'primary-signature': $petz-color-primary-brand,
  'secondary-brand': $petz-color-neutral-darker-accent,
  'sup-yellow': $petz-color-neutral-darker-accent,
  'sup-green': $petz-color-neutral-white,
  'sup-red': $petz-color-neutral-white,
  'sup-orange': $petz-color-neutral-white,
  'sup-green-light': $petz-color-neutral-darker-accent,
  'sup-green-dark': $petz-color-neutral-white,
  'neutral-dark': $petz-color-neutral-white,
  'neutral-light': $petz-color-neutral-darker-accent,
  'chart-blue': $petz-color-primary-brand,
  'chart-purple': $petz-color-chart-purple-dark,
  'chart-pink': $petz-color-chart-pink-dark,
  'chart-orange': $petz-color-chart-orange-dark,
  'chart-orange-dark': $petz-color-neutral-white,
);

$lineColors: (
  'primary-brand': $petz-color-primary-brand,
  'primary-signature': $petz-color-primary-signature,
  'secondary-brand': $petz-color-secondary-dark,
  'sup-yellow': $petz-color-sup-yellow-darker-accent,
  'sup-green': $petz-color-sup-green-darker-accent,
  'sup-red': $petz-color-sup-red-darker-accent,
  'sup-orange': $petz-color-chart-orange-dark,
  'sup-green-light': $petz-color-neutral-darker-accent,
  'sup-green-dark': $petz-color-sup-green-darker-accent,
  'neutral-dark': $petz-color-neutral-darker-accent,
  'neutral-light': $petz-color-neutral-mid,
  'chart-blue': $petz-color-chart-blue-dark,
  'chart-purple': $petz-color-chart-purple-dark,
  'chart-pink': $petz-color-chart-pink-dark,
  'chart-orange': $petz-color-chart-orange-dark,
  'chart-orange-dark': $petz-color-chart-orange-dark,
);

$lineTextColors: (
  'primary-brand': $petz-color-primary-brand,
  'primary-signature': $petz-color-primary-brand,
  'secondary-brand': $petz-color-secondary-dark,
  'sup-yellow': $petz-color-sup-yellow-darker-accent,
  'sup-green': $petz-color-sup-green-darker-accent,
  'sup-red': $petz-color-sup-red-darker-accent,
  'sup-orange': $petz-color-chart-orange-dark,
  'sup-green-light': $petz-color-neutral-darker-accent,
  'sup-green-dark': $petz-color-sup-green-darker-accent,
  'neutral-dark': $petz-color-neutral-darker-accent,
  'neutral-light': $petz-color-neutral-dark,
  'chart-blue': $petz-color-chart-blue-dark,
  'chart-purple': $petz-color-chart-purple-dark,
  'chart-pink': $petz-color-chart-pink-dark,
  'chart-orange': $petz-color-chart-orange-dark,
  'chart-orange-dark': $petz-color-chart-orange-dark,
);

ptz-badge {
  .ptz-badge {
    display: inline-flex;
    box-sizing: border-box;
    border-radius: $petz-rounded-max;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: $petz-spacing-2xs;

    &-span {
      font-weight: $petz-font-weight-bold;
      line-height: $petz-font-lineheight-xs;
    }

    ptz-icon {
      display: inline-flex;
    }

    @each $id, $bgColor in $solidColors {
      &-#{$id}-solid {
        background-color: $bgColor;
        color: map-get($map: $solidTextColors, $key: $id);
      }
    }

    @each $id, $borderColor in $lineColors {
      &-#{$id}-line {
        border: $petz-border-sm solid;
        border-color: $borderColor;
        color: map-get($map: $lineTextColors, $key: $id);
      }
    }

    @each $size, $padding in $sizes {
      &-#{$size} {
        padding: $padding;

        @if $size == 'xs' {
          .ptz-badge-span {
            font-size: 0.625rem;
          }
        } @else if $size == 'lg' {
          .ptz-badge-span {
            font-size: $petz-font-size-2xs;
          }
        } @else {
          .ptz-badge-span {
            font-size: $petz-font-size-3xs;
          }
        }
      }
    }
  }
}

$srsSizes: (
  'lg': $seres-spacing-xs $seres-spacing-md,
  'md': $seres-spacing-2xs $seres-spacing-xs,
  'sm': $seres-spacing-4xs $seres-spacing-2xs,
  'xs': $seres-spacing-4xs $seres-spacing-3xs,
);

$srsSolidColors: (
  'primary-brand': $seres-color-primary-brand,
  'neutral-dark': $seres-color-neutral-darker-accent,
  'neutral-light': $seres-color-neutral-lighter-accent,
  'sup-green': $seres-color-sup-green-darker-accent,
  'sup-red': $seres-color-sup-red-darker-accent,
  'sup-orange': $seres-color-chart-orange-dark,
  'chart-orange-dark': $seres-color-chart-orange-dark,
  'sup-yellow': $seres-color-sup-yellow-mid,
  'secondary-brand': $seres-color-secondary-brand,
  'primary-signature': $petz-color-primary-brand,
  'sup-green-light': $seres-color-sup-green-light,
);

$srsSolidTextColors: (
  'primary-brand': $seres-color-neutral-white,
  'neutral-dark': $seres-color-neutral-white,
  'neutral-light': $seres-color-neutral-darker-accent,
  'sup-green': $seres-color-neutral-white,
  'sup-red': $seres-color-neutral-white,
  'sup-orange': $seres-color-neutral-white,
  'chart-orange-dark': $seres-color-neutral-white,
  'sup-yellow': $seres-color-neutral-darker-accent,
  'secondary-brand': $seres-color-neutral-darker-accent,
  'primary-signature': $seres-color-neutral-white,
  'sup-green-light': $seres-color-neutral-darker-accent,
);

$srsLineColors: (
  'primary-brand': $seres-color-primary-brand,
  'neutral-dark': $seres-color-neutral-darker-accent,
  'neutral-light': $seres-color-neutral-mid,
  'sup-green': $seres-color-sup-green-darker-accent,
  'sup-red': $seres-color-sup-red-darker-accent,
  'sup-orange': $seres-color-chart-orange-dark,
  'chart-orange-dark': $seres-color-chart-orange-dark,
  'sup-yellow': $seres-color-sup-yellow-mid,
  'secondary-brand': $seres-color-secondary-brand,
  'primary-signature': $petz-color-primary-brand,
  'sup-green-light': $seres-color-sup-green-light,
);

$srsLineTextColors: (
  'primary-brand': $seres-color-primary-brand,
  'primary-signature': $petz-color-primary-brand,
  'secondary-brand': $seres-color-secondary-brand,
  'sup-yellow': $seres-color-sup-yellow-mid,
  'sup-green': $seres-color-sup-green-darker-accent,
  'sup-red': $seres-color-sup-red-darker-accent,
  'sup-orange': $seres-color-chart-orange-dark,
  'sup-green-light': $seres-color-sup-green-light,
  'neutral-dark': $seres-color-neutral-darker-accent,
  'neutral-light': $seres-color-neutral-dark,
  'chart-orange-dark': $seres-color-chart-orange-dark,
);

ptz-badge {
  .srs-badge {
    display: inline-flex;
    box-sizing: border-box;
    border-radius: $seres-rounded-max;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: $seres-spacing-2xs;

    &-span {
      font-weight: $seres-font-weight-bold;
    }

    ptz-icon {
      display: inline-flex;
    }

    @each $id, $bgColor in $srsSolidColors {
      &-#{$id}-solid {
        background-color: $bgColor;
        color: map-get($map: $srsSolidTextColors, $key: $id);
      }
    }

    @each $id, $borderColor in $srsLineColors {
      &-#{$id}-line {
        border: $seres-border-sm solid;
        border-color: $borderColor;
        color: map-get($map: $srsLineTextColors, $key: $id);
      }
    }

    @each $size, $padding in $srsSizes {
      &-#{$size} {
        padding: $padding;

        @if $size == 'xs' {
          gap: $seres-spacing-4xs;
          .srs-badge-span {
            font-size: 0.625rem;
            line-height: $seres-font-lineheight-xs;
          }
        } @else if $size == 'sm' {
          gap: $seres-spacing-3xs;
          .srs-badge-span {
            font-size: $seres-font-size-3xs;
            line-height: $seres-font-lineheight-xs;
          }
        } @else if $size == 'lg' {
          .srs-badge-span {
            font-size: $seres-font-size-2xs;
            line-height: $seres-font-lineheight-lg;
          }
        } @else {
          .srs-badge-span {
            font-size: $seres-font-size-3xs;
            line-height: $seres-font-lineheight-xs;
          }
        }
      }
    }
  }
}
