import { Component, h, Host, Prop } from '@stencil/core';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZBadgeConfig } from './types/ptz-badge.enums';
import { PTZBadgeTypes } from './types/ptz-badge.types';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';

@Component({
  tag: 'ptz-badge',
  styleUrl: 'ptz-badge.scss',
  shadow: false,
})
export class PTZBadge {
  /** Cor onde o botão é aplicado */
  @Prop() color?: PTZBadgeTypes.Color;

  /** Alinhamento do ícone no badge */
  @Prop() iconAlign?: PTZIconTypes.Align;

  /** Nome do ícone no badge */
  @Prop() iconName?: PTZIconTypes.Name;

  /** Variante do ícone no badge */
  @Prop() iconVariant?: PTZIconTypes.Variant;

  /** Tamanho do badge */
  @Prop() size?: PTZBadgeTypes.Size;

  /** Variante do badge, se é sólido ou em linha */
  @Prop() variant?: PTZBadgeTypes.Variant;

  /** Define se vai utilizar estilos da Petz ou de outra marca */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz;
  
  /** Define se a role do span vai ser tooltip ou outra */
  @Prop() kind?: PTZBadgeTypes.Kind

  render() {
    const colorClass = this.color ? this.color : 'primary-brand';

    const variantClass = this.variant ? this.variant : 'solid';

    const kind = this.kind ? this.kind : 'status';

    const sizeClass = this.size ? `${this.brand}-badge-${this.size}` : `${this.brand}-badge-sm`;

    const iconAlignClass = this.iconAlign ? `${this.brand}-badge-${this.iconAlign}` : '';

    const classBadge = `
      ${this.brand}-badge
      ${this.brand}-badge-${colorClass}-${variantClass}
      ${sizeClass}
      ${iconAlignClass}
    `;

    const iconLeft = this.iconName && this.iconVariant && this.iconAlign === PTZBadgeConfig.IconAlign.Left && (
      <ptz-icon class={`${this.brand}-badge-icon`} name={this.iconName} variant={this.iconVariant} size="md"></ptz-icon>
    );

    const iconRight = this.iconName && this.iconVariant && this.iconAlign === PTZBadgeConfig.IconAlign.Right && (
      <ptz-icon class={`${this.brand}-badge-icon`} name={this.iconName} variant={this.iconVariant} size="md"></ptz-icon>
    );

    return (
      <Host>
        <div class={classBadge}>
          {iconLeft}
          <span
            role={kind}
            class={`
              ${this.brand}-badge-span
            `}
          >
            <slot />
          </span>
          {iconRight}
        </div>
      </Host>
    );
  }
}
